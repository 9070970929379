import { useRef, useEffect } from "react";

export default function ButtonComponent() {
    const buttonRef = useRef(null);

    useEffect(() => {
        buttonRef.current.focus();
      }, []);

    return <button ref={buttonRef}>Click Me!</button>;
}