import { Link } from "react-router-dom";
import ClassInput from './Classinput';
import FunctionalInput from './Functioninput';
import Image from './Image';
import './Popeye.css';
import ButtonComponent from "./components/ButtonComponents";
const Popeye = () => {
  return (
    <>
      <p>Hi, I am Popeye! I love to eat Spinach!</p>
      <Image />
      <h2>ClassInput</h2>
      <ClassInput />
      <h2>FunctionalInput</h2>
      <FunctionalInput />
      <ButtonComponent />
      <Link to="/">Click here to go back</Link>
    </>
  );
};

export default Popeye;